/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.btn:focus {
  outline: 0;
  box-shadow: none;
}

/**
  /// Init Sidebar Styles ////
*/
.ng-sidebar {
  z-index: 111 !important;
  max-width: 26.438rem;
  min-width: 100vw;
  min-height: 100vh;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  &.ng-sidebar--closed {
    margin-left: 0;
  }
}

/**
  /// Finish Sidebar Styles ////
*/

/** Init Modal ReQuote  **/

.reQuoteDialog.modal-dialog {
  position: fixed;
  bottom: 0px;
  right: 0;
  left: 0;
}
.reQuoteDialog .modal-content {
  width: 100%;
  margin: auto;
  border: none;
  background: transparent;
}
.reQuoteDialog .cotizador {
  top: auto !important;
}
/** Fin Modal ReQuote  **/

.replanDialog .modal-dialog {
  max-width: 85% !important;
  margin: 1.75rem auto;
}

.replanDialog .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  pointer-events: auto;
  background-color: #fff0;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.replanDialog .modal-dialog {
  max-width: 90% !important;
  margin: 1.75rem auto;
}

// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  /// Init Sidebar Styles ////
  .ng-sidebar {
    min-width: 18.125rem;
  }
  /// Finish Sidebar Styles ////
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  /// Init Sidebar Styles ////
  .ng-sidebar {
    margin-left: 7.5rem;
  }
  /// Finish Sidebar Styles ////
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1600px) {
}

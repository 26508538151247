/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@glidejs/glide/src/assets/sass/glide.core";
@import "~@glidejs/glide/src/assets/sass/glide.theme";

// Theme customization
@import "theme/theme";
@import "theme/fonts";
@import "theme/texts";
@import "theme/animated";

@font-face {
  font-family: "IBM SemiBold";
  src: url("IBMPlexSans-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "IBM Medium";
  src: url("IBMPlexSans-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "IBM Bold";
  src: url("IBMPlexSans-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("OpenSans-ExtraBold.otf");
  font-display: swap;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
.form-group {
  font-family: "IBM Medium" !important;
}

span.pi {
  font-family: primeicons !important;
}

.glide__bullets {
  position: relative;
  width: 100%;
  text-align: center;
  top: 20px;
}
.glide__bullet {
  background-color: #004686;
  width: 11px;
  height: 11px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer !important;
  line-height: 0;
  box-shadow: 0 0.25em 0.3em 0 rgb(70 70 70 / 68%);
  margin: 0 0.25em;
}

.glide__arrows,
.glide__arrow--right,
.glide__arrow--left {
  display: block !important;
  background: #bdbdbd !important;
  border-radius: 50%;
  font-weight: bolder;
}

.glide__track {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.menu-ham {
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}

.w100 {
  width: 100%;
}

.hauto {
  height: auto;
}

.wauto {
  width: auto;
}

* {
  -webkit-print-color-adjust: exact !important;
}

.modal-plan .modal-dialog {
  max-width: 1050px;
}

.modal-services {
  .modal-content {
    border-radius: 35px !important;
  }
}

.panel-faq {
  border: 0px;
}

.panel-faq > .accordion-header > .accordion-button {
  background: white;
  color: black;
  box-shadow: none;
}

.panel-faq > .accordion-header > .accordion-button > h3 {
  font-size: 1.4rem;
}

.panel-faq > .collapse > .accordion-body {
  font-size: 1rem;
}

.carousel-indicators {
  margin-bottom: 0rem !important;
}

.carousel-control-prev,
.carousel-control-next {
  display: block !important;
}

#our-services > .carousel-control-next {
  height: 50px;
  top: auto;
  bottom: -65px;
  right: 45%;
  width: 50px;
}

#our-services > .carousel-control-prev {
  height: 50px;
  top: auto;
  bottom: -65px;
  left: 45%;
  width: 50px;
}

#our-services > .carousel-inner {
  overflow: visible;
}

.picsum-img-wrapper > img {
  object-fit: cover;
  object-position: top;
}

.p-scrolltop {
  left: 20px !important;
  background-image: url("https://res.cloudinary.com/pervolare-org/image/upload/v1702229263/TAS/home/scrolltop_fe7vqw.svg") !important;
  background-size: contain !important;
  border: 2px solid #d9d9d9;
  background-color: #d9d9d9 !important;
  span {
    display: none;
  }
}

// @font-face {
//   font-family: "Rollete Qaku";
//   src: url(~src/assets/fonts/RolleteQaku-Regular.otf);
//   font-style: normal;
// }

// * {
//   font-family: "Rollete Qaku";
// }

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 11px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}
.font-size-24 {
  font-size: 24px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-40 {
  font-size: 40px;
}

.bolder {
  font-weight: 500;
}

.n-bolder {
  font-weight: 100 !important;
}

.center {
  align-items: center;
}

@media (min-width: 320px) {
  .font-size-xs-20 {
    font-size: 20px;
  }

  .font-size-xs-11 {
    font-size: 11px !important;
  }

  .font-size-xs-12 {
    font-size: 12px;
  }

  .font-size-xs-15 {
    font-size: 15px;
  }

  .cont-quote {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .font-size-md-20 {
    font-size: 20px;
  }
  .font-size-md-26 {
    font-size: 26px;
  }

  .font-size-md-21 {
    font-size: 21px;
  }

  .font-size-md-22 {
    font-size: 22px !important;
  }

  .font-size-md-14 {
    font-size: 14px;
  }

  .font-size-md-18 {
    font-size: 18px;
  }

  .font-size-md-22 {
    font-size: 22px;
  }

  .font-size-md-16 {
    font-size: 16px;
  }

  .font-size-md-30 {
    font-size: 30px;
  }
  .font-size-md-32 {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .font-size-lg-11 {
    font-size: 11px;
  }
  .font-size-lg-9 {
    font-size: 9px;
  }
  .font-size-lg-13 {
    font-size: 13px;
  }

  .font-size-lg-14 {
    font-size: 14px;
  }
  .font-size-lg-15 {
    font-size: 15px;
  }

  .font-size-lg-16 {
    font-size: 16px;
  }

  .font-size-lg-17 {
    font-size: 18px;
  }
  .font-size-lg-18 {
    font-size: 18px;
  }
  .font-size-lg-20 {
    font-size: 20px;
  }
  .font-size-lg-22 {
    font-size: 22px !important;
  }
  .font-size-lg-23 {
    font-size: 23px;
  }
  .font-size-lg-25 {
    font-size: 25px;
  }
  .font-size-lg-26 {
    font-size: 26px;
  }
  .font-size-lg-28 {
    font-size: 28px;
  }

  .font-size-lg-30 {
    font-size: 30px;
  }
  .font-size-lg-bold {
    font-weight: bold;
  }
  .font-size-lg-32 {
    font-size: 32px;
  }
  .font-size-lg-40 {
    font-size: 40px;
  }
}

@media (min-width: 1400px) {
  .font-size-xl-11 {
    font-size: 11px;
  }
  .font-size-xl-9 {
    font-size: 9px;
  }
  .font-size-xl-13 {
    font-size: 13px;
  }

  .font-size-xl-14 {
    font-size: 14px;
  }
  .font-size-xl-15 {
    font-size: 15px;
  }

  .font-size-xl-16 {
    font-size: 16px;
  }

  .font-size-xl-17 {
    font-size: 18px;
  }
  .font-size-xl-18 {
    font-size: 18px;
  }
  .font-size-xl-20 {
    font-size: 20px;
  }
  .font-size-xl-22 {
    font-size: 22px !important;
  }
  .font-size-xl-25 {
    font-size: 25px;
  }
  .font-size-xl-26 {
    font-size: 26px;
  }
  .font-size-xl-28 {
    font-size: 28px;
  }

  .font-size-xl-30 {
    font-size: 30px;
  }
  .font-size-xl-bold {
    font-weight: bold;
  }
  .font-size-xl-32 {
    font-size: 32px;
  }
  .font-size-xl-40 {
    font-size: 40px;
  }
}

@media (min-width: 1400px) {
  .font-size-xxl-11 {
    font-size: 11px;
  }
  .font-size-xxl-9 {
    font-size: 9px;
  }
  .font-size-xxl-13 {
    font-size: 13px;
  }

  .font-size-xxl-14 {
    font-size: 14px;
  }
  .font-size-xxl-15 {
    font-size: 15px;
  }

  .font-size-xxl-16 {
    font-size: 16px;
  }

  .font-size-xxl-17 {
    font-size: 18px;
  }
  .font-size-xxl-18 {
    font-size: 18px;
  }
  .font-size-xxl-20 {
    font-size: 20px;
  }
  .font-size-xxl-22 {
    font-size: 22px;
  }
  .font-size-xxl-24 {
    font-size: 24px;
  }
  .font-size-xxl-26 {
    font-size: 26px;
  }
  .font-size-xxl-28 {
    font-size: 28px;
  }

  .font-size-xxl-30 {
    font-size: 30px;
  }
  .font-size-xxl-bold {
    font-weight: bold;
  }
  .font-size-xxl-32 {
    font-size: 32px;
  }
}
